
import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';

import MailingModule from '@/admin/store/mailing';

@Component({
    name: 'Mailing',
    components: {
        Layout,
        FlexLayout,
        TitleReturn,
        TableBlock,
        AppHeaderMain,
    },
})
export default class Mailing extends Vue {
    get settings() {
        return MailingModule.pageSettings;
    }

    get paginationAction() {
        return MailingModule.updatePage;
    }

    get filterSettings() {
        return MailingModule.filter.filterSettings;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: MailingModule.updateItems,
            items: MailingModule.pageSettings.pageAmountItems,
        };
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            MailingModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        MailingModule.getList();
    }

    async resetFilters() {
        await MailingModule.clearSort();
        await MailingModule.filter.resetFilter();
        MailingModule.getList();
    }

    toAdd() {
        this.$router.push('/mailing/create');
    }

    mounted(): void {
        MailingModule.init();
    }

    beforeDestroy(): void {
        MailingModule.reset();
    }
}
