/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class MailingFilter {
    filterHandlers = {
        update: 'MailingFilter/updateFilter',
        reset: 'MailingFilter/resetFilter',
        resetState: 'MailingFilter/resetFilterState',
        removeSelected: 'MailingFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSuggestionsEntity('subject');
        filter.setPlaceholder('subject', 'По названию');
        filter.setName('subject', 'suggestions_subject');
        filter.setPreviewName('subject', 'Название');
        filter.setAction('subject', 'mailing/updateSuggestionsSelectedFilter');
        filter.setSearchAction('subject', 'mailing/updateSuggestionsSearchFilter');
        filter.setIconClassName('subject', 'icon-book');

        this.filterModel = filter.filterModel;
    }
}
