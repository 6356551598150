import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest, makeDeleteRequest } from '@/api/helpers';

export const getMailingList = async (pageCurrent: number, sort: string, filter: string, items: number | string) => {
    const itemsFilter = items ? `&items=${items}` : '';
    const result = await makeGetRequest(API.mailing.getList + `?page=${pageCurrent}${itemsFilter}&${sort}${filter}`);

    return result.data.result.table;
};

export const createMail = async (data: any) => {
    const result = await makeFormRequest(API.mailing.create, data);

    return result;
};

export const createMailAll = async (data: any) => {
    const result = await makeFormRequest(API.mailing.createAll, data);

    return result;
};

export const getMail = async (cardId: number | string) => {
    const result = await makeGetRequest(API.mailing.detail + `${cardId}/`);

    return result.data.result.item;
};

export const removeMail = async (params: string): Promise<string> => {
    const result = await makeDeleteRequest(`${API.mailing.remove}${params}/`);

    return result?.data?.result?.status;
};